import messages from "@dp-common/locale/translations/sw-SW.json";
import DPPartsSWLocale from "@dp-parts/locales/translations/sw-SW.json";
import DPUISWLocale from "@dp-ui/locales/translations/sw-SW.json";
import DPUI2SWLocale from "@dp-ui2/locales/translations/_autogenerated/sv-SE.json";
import antdData from "antd4/lib/locale-provider/sv_SE";

export default {
	locale: "sv-SE",
	antdData,
	messages: {
		...DPUI2SWLocale,
		...messages,
		...DPPartsSWLocale,
		...DPUISWLocale,
		openStatsView: "Öppna statistikvy",
		recentSelections: "Senaste val",
		allFacilities: "Alla anläggningar",
		receiverEmail: "Mottagarens e-post",
		downloadTemplate: "Ladda ner mall",
		deleteMeter: "Radera Mätare",
		deleteMeterConfirmation: "Är du säker på att du vill radera denna mätare?",
		deleteMeterWithReadings: "Radera Mätare och alla Mätvärden",
	},
};
